import Vue from 'vue'
import { wrapFunctional } from './utils'

const components = {
  OpusAccordion: () => import('../../src/modules/opus/components/Accordion.vue' /* webpackChunkName: "components/opus-accordion" */).then(c => wrapFunctional(c.default || c)),
  OpusAccordionItem: () => import('../../src/modules/opus/components/AccordionItem.vue' /* webpackChunkName: "components/opus-accordion-item" */).then(c => wrapFunctional(c.default || c)),
  OpusCard: () => import('../../src/modules/opus/components/Card.vue' /* webpackChunkName: "components/opus-card" */).then(c => wrapFunctional(c.default || c)),
  OpusIcon: () => import('../../src/modules/opus/components/Icon.vue' /* webpackChunkName: "components/opus-icon" */).then(c => wrapFunctional(c.default || c)),
  OpusLink: () => import('../../src/modules/opus/components/Link.vue' /* webpackChunkName: "components/opus-link" */).then(c => wrapFunctional(c.default || c)),
  OpusModal: () => import('../../src/modules/opus/components/Modal.vue' /* webpackChunkName: "components/opus-modal" */).then(c => wrapFunctional(c.default || c)),
  OpusModalTwo: () => import('../../src/modules/opus/components/ModalTwo.vue' /* webpackChunkName: "components/opus-modal-two" */).then(c => wrapFunctional(c.default || c)),
  OpusQuantitySelector: () => import('../../src/modules/opus/components/QuantitySelector.vue' /* webpackChunkName: "components/opus-quantity-selector" */).then(c => wrapFunctional(c.default || c)),
  OpusSelect: () => import('../../src/modules/opus/components/Select.vue' /* webpackChunkName: "components/opus-select" */).then(c => wrapFunctional(c.default || c)),
  OpusTitle: () => import('../../src/modules/opus/components/Title.vue' /* webpackChunkName: "components/opus-title" */).then(c => wrapFunctional(c.default || c)),
  OpusButton: () => import('../../src/modules/opus/components/Form/Button.vue' /* webpackChunkName: "components/opus-button" */).then(c => wrapFunctional(c.default || c)),
  OpusCheckbox: () => import('../../src/modules/opus/components/Form/Checkbox.vue' /* webpackChunkName: "components/opus-checkbox" */).then(c => wrapFunctional(c.default || c)),
  OpusInput: () => import('../../src/modules/opus/components/Form/Input.vue' /* webpackChunkName: "components/opus-input" */).then(c => wrapFunctional(c.default || c)),
  OpusLabel: () => import('../../src/modules/opus/components/Form/Label.vue' /* webpackChunkName: "components/opus-label" */).then(c => wrapFunctional(c.default || c)),
  OpusRangeSlider: () => import('../../src/modules/opus/components/Form/RangeSlider.vue' /* webpackChunkName: "components/opus-range-slider" */).then(c => wrapFunctional(c.default || c)),
  OpusRenderInput: () => import('../../src/modules/opus/components/Form/RenderInput.vue' /* webpackChunkName: "components/opus-render-input" */).then(c => wrapFunctional(c.default || c)),
  BreadCrumbs: () => import('../../src/modules/opus-ecom/components/BreadCrumbs.vue' /* webpackChunkName: "components/bread-crumbs" */).then(c => wrapFunctional(c.default || c)),
  Cart: () => import('../../src/modules/opus-ecom/components/Cart.vue' /* webpackChunkName: "components/cart" */).then(c => wrapFunctional(c.default || c)),
  ColorMode: () => import('../../src/modules/opus-ecom/components/ColorMode.vue' /* webpackChunkName: "components/color-mode" */).then(c => wrapFunctional(c.default || c)),
  CookieConsent: () => import('../../src/modules/opus-ecom/components/CookieConsent.vue' /* webpackChunkName: "components/cookie-consent" */).then(c => wrapFunctional(c.default || c)),
  Footer: () => import('../../src/modules/opus-ecom/components/Footer.vue' /* webpackChunkName: "components/footer" */).then(c => wrapFunctional(c.default || c)),
  LayeredFilters: () => import('../../src/modules/opus-ecom/components/LayeredFilters.vue' /* webpackChunkName: "components/layered-filters" */).then(c => wrapFunctional(c.default || c)),
  LayeredNavigation: () => import('../../src/modules/opus-ecom/components/LayeredNavigation.vue' /* webpackChunkName: "components/layered-navigation" */).then(c => wrapFunctional(c.default || c)),
  LayeredPagination: () => import('../../src/modules/opus-ecom/components/LayeredPagination.vue' /* webpackChunkName: "components/layered-pagination" */).then(c => wrapFunctional(c.default || c)),
  LayeredSort: () => import('../../src/modules/opus-ecom/components/LayeredSort.vue' /* webpackChunkName: "components/layered-sort" */).then(c => wrapFunctional(c.default || c)),
  Logo: () => import('../../src/modules/mobiilitukku/components/Logo.vue' /* webpackChunkName: "components/logo" */).then(c => wrapFunctional(c.default || c)),
  Navigation: () => import('../../src/modules/opus-ecom/components/Navigation.vue' /* webpackChunkName: "components/navigation" */).then(c => wrapFunctional(c.default || c)),
  NewProducts: () => import('../../src/modules/opus-ecom/components/NewProducts.vue' /* webpackChunkName: "components/new-products" */).then(c => wrapFunctional(c.default || c)),
  ProductCard: () => import('../../src/modules/opus-ecom/components/ProductCard.vue' /* webpackChunkName: "components/product-card" */).then(c => wrapFunctional(c.default || c)),
  ProductLabel: () => import('../../src/modules/opus-ecom/components/ProductLabel.vue' /* webpackChunkName: "components/product-label" */).then(c => wrapFunctional(c.default || c)),
  ProductList: () => import('../../src/modules/opus-ecom/components/ProductList.vue' /* webpackChunkName: "components/product-list" */).then(c => wrapFunctional(c.default || c)),
  ProductPageSkeleton: () => import('../../src/modules/opus-ecom/components/ProductPageSkeleton.vue' /* webpackChunkName: "components/product-page-skeleton" */).then(c => wrapFunctional(c.default || c)),
  ProductReview: () => import('../../src/modules/opus-ecom/components/ProductReview.vue' /* webpackChunkName: "components/product-review" */).then(c => wrapFunctional(c.default || c)),
  ProductReviewModal: () => import('../../src/modules/opus-ecom/components/ProductReviewModal.vue' /* webpackChunkName: "components/product-review-modal" */).then(c => wrapFunctional(c.default || c)),
  Quicksearch: () => import('../../src/modules/opus-ecom/components/Quicksearch.vue' /* webpackChunkName: "components/quicksearch" */).then(c => wrapFunctional(c.default || c)),
  ReadMore: () => import('../../src/modules/opus-ecom/components/ReadMore.vue' /* webpackChunkName: "components/read-more" */).then(c => wrapFunctional(c.default || c)),
  RecentlyViewed: () => import('../../src/modules/opus-ecom/components/RecentlyViewed.vue' /* webpackChunkName: "components/recently-viewed" */).then(c => wrapFunctional(c.default || c)),
  RelatedProducts: () => import('../../src/modules/opus-ecom/components/RelatedProducts.vue' /* webpackChunkName: "components/related-products" */).then(c => wrapFunctional(c.default || c)),
  SubscribeOutOfStock: () => import('../../src/modules/opus-ecom/components/SubscribeOutOfStock.vue' /* webpackChunkName: "components/subscribe-out-of-stock" */).then(c => wrapFunctional(c.default || c)),
  AccountNagivation: () => import('../../src/modules/opus-ecom/components/Account/AccountNagivation.vue' /* webpackChunkName: "components/account-nagivation" */).then(c => wrapFunctional(c.default || c)),
  CustomerReviews: () => import('../../src/modules/opus-ecom/components/Account/CustomerReviews.vue' /* webpackChunkName: "components/customer-reviews" */).then(c => wrapFunctional(c.default || c)),
  OrderRenderer: () => import('../../src/modules/opus-ecom/components/Account/OrderRenderer.vue' /* webpackChunkName: "components/order-renderer" */).then(c => wrapFunctional(c.default || c)),
  OrderRendererItem: () => import('../../src/modules/opus-ecom/components/Account/OrderRendererItem.vue' /* webpackChunkName: "components/order-renderer-item" */).then(c => wrapFunctional(c.default || c)),
  Reorder: () => import('../../src/modules/opus-ecom/components/Account/Reorder.vue' /* webpackChunkName: "components/reorder" */).then(c => wrapFunctional(c.default || c)),
  CartItem: () => import('../../src/modules/opus-ecom/components/Cart/CartItem.vue' /* webpackChunkName: "components/cart-item" */).then(c => wrapFunctional(c.default || c)),
  AddToCart: () => import('../../src/modules/opus-ecom/components/Catalog/AddToCart.vue' /* webpackChunkName: "components/add-to-cart" */).then(c => wrapFunctional(c.default || c)),
  CrossProductItem: () => import('../../src/modules/opus-ecom/components/Catalog/CrossProductItem.vue' /* webpackChunkName: "components/cross-product-item" */).then(c => wrapFunctional(c.default || c)),
  CrossProducts: () => import('../../src/modules/opus-ecom/components/Catalog/CrossProducts.vue' /* webpackChunkName: "components/cross-products" */).then(c => wrapFunctional(c.default || c)),
  InsuranceProductCard: () => import('../../src/modules/opus-ecom/components/Catalog/InsuranceProductCard.vue' /* webpackChunkName: "components/insurance-product-card" */).then(c => wrapFunctional(c.default || c)),
  InsuranceProductModal: () => import('../../src/modules/opus-ecom/components/Catalog/InsuranceProductModal.vue' /* webpackChunkName: "components/insurance-product-modal" */).then(c => wrapFunctional(c.default || c)),
  ProductCardAddToCart: () => import('../../src/modules/opus-ecom/components/Catalog/ProductCardAddToCart.vue' /* webpackChunkName: "components/product-card-add-to-cart" */).then(c => wrapFunctional(c.default || c)),
  ProductImageGallery: () => import('../../src/modules/opus-ecom/components/Catalog/ProductImageGallery.vue' /* webpackChunkName: "components/product-image-gallery" */).then(c => wrapFunctional(c.default || c)),
  ProductReviewCard: () => import('../../src/modules/opus-ecom/components/Catalog/ProductReviewCard.vue' /* webpackChunkName: "components/product-review-card" */).then(c => wrapFunctional(c.default || c)),
  ProductReviewSection: () => import('../../src/modules/opus-ecom/components/Catalog/ProductReviewSection.vue' /* webpackChunkName: "components/product-review-section" */).then(c => wrapFunctional(c.default || c)),
  ProductReviewStars: () => import('../../src/modules/opus-ecom/components/Catalog/ProductReviewStars.vue' /* webpackChunkName: "components/product-review-stars" */).then(c => wrapFunctional(c.default || c)),
  WishlistButton: () => import('../../src/modules/opus-ecom/components/Catalog/WishlistButton.vue' /* webpackChunkName: "components/wishlist-button" */).then(c => wrapFunctional(c.default || c)),
  CheckoutAddress: () => import('../../src/modules/opus-ecom/components/Checkout/CheckoutAddress.vue' /* webpackChunkName: "components/checkout-address" */).then(c => wrapFunctional(c.default || c)),
  KlarnaPaymentMethods: () => import('../../src/modules/opus-ecom/components/Checkout/KlarnaPaymentMethods.vue' /* webpackChunkName: "components/klarna-payment-methods" */).then(c => wrapFunctional(c.default || c)),
  PaymentMethods: () => import('../../src/modules/opus-ecom/components/Checkout/PaymentMethods.vue' /* webpackChunkName: "components/payment-methods" */).then(c => wrapFunctional(c.default || c)),
  ShippingMethods: () => import('../../src/modules/opus-ecom/components/Checkout/ShippingMethods.vue' /* webpackChunkName: "components/shipping-methods" */).then(c => wrapFunctional(c.default || c)),
  Totals: () => import('../../src/modules/opus-ecom/components/Checkout/Totals.vue' /* webpackChunkName: "components/totals" */).then(c => wrapFunctional(c.default || c)),
  Category: () => import('../../src/modules/mobiilitukku/components/Category.vue' /* webpackChunkName: "components/category" */).then(c => wrapFunctional(c.default || c)),
  Cta: () => import('../../src/modules/opus-ecom/components/Cms/Cta.vue' /* webpackChunkName: "components/cta" */).then(c => wrapFunctional(c.default || c)),
  Hero: () => import('../../src/modules/mobiilitukku/components/Hero.vue' /* webpackChunkName: "components/hero" */).then(c => wrapFunctional(c.default || c)),
  SearchLayeredNavigation: () => import('../../src/modules/opus-ecom/components/Search/SearchLayeredNavigation.vue' /* webpackChunkName: "components/search-layered-navigation" */).then(c => wrapFunctional(c.default || c)),
  ContentfulVideo: () => import('../../src/modules/contentful/components/ContentfulVideo.vue' /* webpackChunkName: "components/contentful-video" */).then(c => wrapFunctional(c.default || c)),
  ContentfulGlobalBanner: () => import('../../src/modules/contentful/components/GlobalBanner.vue' /* webpackChunkName: "components/contentful-global-banner" */).then(c => wrapFunctional(c.default || c)),
  ContentfulProductPage: () => import('../../src/modules/contentful/components/ProductPage.vue' /* webpackChunkName: "components/contentful-product-page" */).then(c => wrapFunctional(c.default || c)),
  ContentfulRichText: () => import('../../src/modules/contentful/components/RichText.vue' /* webpackChunkName: "components/contentful-rich-text" */).then(c => wrapFunctional(c.default || c)),
  ContentfulWidgetList: () => import('../../src/modules/contentful/components/widget-list.vue' /* webpackChunkName: "components/contentful-widget-list" */).then(c => wrapFunctional(c.default || c)),
  ContentfulBlogCard: () => import('../../src/modules/contentful/components/Blog/BlogCard.vue' /* webpackChunkName: "components/contentful-blog-card" */).then(c => wrapFunctional(c.default || c)),
  ContentfulBlogFooter: () => import('../../src/modules/contentful/components/Blog/BlogFooter.vue' /* webpackChunkName: "components/contentful-blog-footer" */).then(c => wrapFunctional(c.default || c)),
  ContentfulBlogHeader: () => import('../../src/modules/contentful/components/Blog/BlogHeader.vue' /* webpackChunkName: "components/contentful-blog-header" */).then(c => wrapFunctional(c.default || c)),
  ContentfulBlogListing: () => import('../../src/modules/contentful/components/Blog/BlogListing.vue' /* webpackChunkName: "components/contentful-blog-listing" */).then(c => wrapFunctional(c.default || c)),
  ContentfulImageBannerHalfImage: () => import('../../src/modules/contentful/components/ImageBanner/ImageBannerHalfImage.vue' /* webpackChunkName: "components/contentful-image-banner-half-image" */).then(c => wrapFunctional(c.default || c)),
  ContentfulImageBannerHero: () => import('../../src/modules/contentful/components/ImageBanner/ImageBannerHero.vue' /* webpackChunkName: "components/contentful-image-banner-hero" */).then(c => wrapFunctional(c.default || c)),
  ContentfulImageBannerSmallBanner: () => import('../../src/modules/contentful/components/ImageBanner/ImageBannerSmallBanner.vue' /* webpackChunkName: "components/contentful-image-banner-small-banner" */).then(c => wrapFunctional(c.default || c)),
  ContentfulAccordion: () => import('../../src/modules/contentful/components/Widget/Accordion.vue' /* webpackChunkName: "components/contentful-accordion" */).then(c => wrapFunctional(c.default || c)),
  ContentfulCallToAction: () => import('../../src/modules/contentful/components/Widget/CallToAction.vue' /* webpackChunkName: "components/contentful-call-to-action" */).then(c => wrapFunctional(c.default || c)),
  ContentfulCallToActionItem: () => import('../../src/modules/contentful/components/Widget/CallToActionItem.vue' /* webpackChunkName: "components/contentful-call-to-action-item" */).then(c => wrapFunctional(c.default || c)),
  ContentfulCategoryPromotion: () => import('../../src/modules/contentful/components/Widget/CategoryPromotion.vue' /* webpackChunkName: "components/contentful-category-promotion" */).then(c => wrapFunctional(c.default || c)),
  ContentfulForm: () => import('../../src/modules/contentful/components/Widget/Form.vue' /* webpackChunkName: "components/contentful-form" */).then(c => wrapFunctional(c.default || c)),
  ContentfulImageBanner: () => import('../../src/modules/contentful/components/Widget/ImageBanner.vue' /* webpackChunkName: "components/contentful-image-banner" */).then(c => wrapFunctional(c.default || c)),
  ContentfulModelSelector: () => import('../../src/modules/contentful/components/Widget/ModelSelector.vue' /* webpackChunkName: "components/contentful-model-selector" */).then(c => wrapFunctional(c.default || c)),
  ContentfulProductList: () => import('../../src/modules/contentful/components/Widget/ProductList.vue' /* webpackChunkName: "components/contentful-product-list" */).then(c => wrapFunctional(c.default || c)),
  ContentfulQuickLinks: () => import('../../src/modules/contentful/components/Widget/QuickLinks.vue' /* webpackChunkName: "components/contentful-quick-links" */).then(c => wrapFunctional(c.default || c)),
  ContentfulTextBanner: () => import('../../src/modules/contentful/components/Widget/TextBanner.vue' /* webpackChunkName: "components/contentful-text-banner" */).then(c => wrapFunctional(c.default || c)),
  ContentfulTextBlock: () => import('../../src/modules/contentful/components/Widget/TextBlock.vue' /* webpackChunkName: "components/contentful-text-block" */).then(c => wrapFunctional(c.default || c)),
  ContentfulUsp: () => import('../../src/modules/contentful/components/Widget/Usp.vue' /* webpackChunkName: "components/contentful-usp" */).then(c => wrapFunctional(c.default || c)),
  ContentfulWysiwyg: () => import('../../src/modules/contentful/components/Widget/Wysiwyg.vue' /* webpackChunkName: "components/contentful-wysiwyg" */).then(c => wrapFunctional(c.default || c)),
  ContentfulBlogPage: () => import('../../src/modules/contentful/components/Widget/blog-page.vue' /* webpackChunkName: "components/contentful-blog-page" */).then(c => wrapFunctional(c.default || c)),
  ContentfulContact: () => import('../../src/modules/contentful/components/Widget/Form/Contact.vue' /* webpackChunkName: "components/contentful-contact" */).then(c => wrapFunctional(c.default || c)),
  AccountBack: () => import('../../src/modules/mobiilitukku/components/AccountBack.vue' /* webpackChunkName: "components/account-back" */).then(c => wrapFunctional(c.default || c)),
  AccountFaq: () => import('../../src/modules/mobiilitukku/components/AccountFaq.vue' /* webpackChunkName: "components/account-faq" */).then(c => wrapFunctional(c.default || c)),
  AccountLevel: () => import('../../src/modules/mobiilitukku/components/AccountLevel.vue' /* webpackChunkName: "components/account-level" */).then(c => wrapFunctional(c.default || c)),
  FooterStoreInfo: () => import('../../src/modules/mobiilitukku/components/FooterStoreInfo.vue' /* webpackChunkName: "components/footer-store-info" */).then(c => wrapFunctional(c.default || c)),
  RegisterBenefits: () => import('../../src/modules/mobiilitukku/components/RegisterBenefits.vue' /* webpackChunkName: "components/register-benefits" */).then(c => wrapFunctional(c.default || c)),
  RegisterCard: () => import('../../src/modules/mobiilitukku/components/RegisterCard.vue' /* webpackChunkName: "components/register-card" */).then(c => wrapFunctional(c.default || c)),
  TukkuBenefits: () => import('../../src/modules/mobiilitukku/components/TukkuBenefits.vue' /* webpackChunkName: "components/tukku-benefits" */).then(c => wrapFunctional(c.default || c)),
  LevelMeter: () => import('../../src/modules/mobiilitukku/components/AccountLevel/LevelMeter.vue' /* webpackChunkName: "components/level-meter" */).then(c => wrapFunctional(c.default || c)),
  BillingShippingAddress: () => import('../../src/components/BillingShippingAddress.vue' /* webpackChunkName: "components/billing-shipping-address" */).then(c => wrapFunctional(c.default || c)),
  CartTotals: () => import('../../src/components/CartTotals.vue' /* webpackChunkName: "components/cart-totals" */).then(c => wrapFunctional(c.default || c)),
  CategoryPage: () => import('../../src/components/CategoryPage.vue' /* webpackChunkName: "components/category-page" */).then(c => wrapFunctional(c.default || c)),
  CategoryTree: () => import('../../src/components/CategoryTree.vue' /* webpackChunkName: "components/category-tree" */).then(c => wrapFunctional(c.default || c)),
  CheckoutBillingAddress: () => import('../../src/components/CheckoutBillingAddress.vue' /* webpackChunkName: "components/checkout-billing-address" */).then(c => wrapFunctional(c.default || c)),
  CheckoutNewsletter: () => import('../../src/components/CheckoutNewsletter.vue' /* webpackChunkName: "components/checkout-newsletter" */).then(c => wrapFunctional(c.default || c)),
  CheckoutNotLoggedIn: () => import('../../src/components/CheckoutNotLoggedIn.vue' /* webpackChunkName: "components/checkout-not-logged-in" */).then(c => wrapFunctional(c.default || c)),
  CheckoutStep: () => import('../../src/components/CheckoutStep.vue' /* webpackChunkName: "components/checkout-step" */).then(c => wrapFunctional(c.default || c)),
  CheckoutSummaryAddress: () => import('../../src/components/CheckoutSummaryAddress.vue' /* webpackChunkName: "components/checkout-summary-address" */).then(c => wrapFunctional(c.default || c)),
  Coupon: () => import('../../src/components/Coupon.vue' /* webpackChunkName: "components/coupon" */).then(c => wrapFunctional(c.default || c)),
  ErrorPage: () => import('../../src/components/ErrorPage.vue' /* webpackChunkName: "components/error-page" */).then(c => wrapFunctional(c.default || c)),
  EstimatedShipping: () => import('../../src/components/EstimatedShipping.vue' /* webpackChunkName: "components/estimated-shipping" */).then(c => wrapFunctional(c.default || c)),
  FreeShippingNotification: () => import('../../src/components/FreeShippingNotification.vue' /* webpackChunkName: "components/free-shipping-notification" */).then(c => wrapFunctional(c.default || c)),
  Klarna: () => import('../../src/components/Klarna.vue' /* webpackChunkName: "components/klarna" */).then(c => wrapFunctional(c.default || c)),
  KlarnaSuccess: () => import('../../src/components/KlarnaSuccess.vue' /* webpackChunkName: "components/klarna-success" */).then(c => wrapFunctional(c.default || c)),
  Notification: () => import('../../src/components/Notification.vue' /* webpackChunkName: "components/notification" */).then(c => wrapFunctional(c.default || c)),
  PaytrailMethods: () => import('../../src/components/PaytrailMethods.vue' /* webpackChunkName: "components/paytrail-methods" */).then(c => wrapFunctional(c.default || c)),
  ProductPage: () => import('../../src/components/ProductPage.vue' /* webpackChunkName: "components/product-page" */).then(c => wrapFunctional(c.default || c)),
  ProtectionLevel: () => import('../../src/components/ProtectionLevel.vue' /* webpackChunkName: "components/protection-level" */).then(c => wrapFunctional(c.default || c)),
  RenderAddress: () => import('../../src/components/RenderAddress.vue' /* webpackChunkName: "components/render-address" */).then(c => wrapFunctional(c.default || c)),
  ShippingLocator: () => import('../../src/components/ShippingLocator.vue' /* webpackChunkName: "components/shipping-locator" */).then(c => wrapFunctional(c.default || c)),
  Slider: () => import('../../src/components/Slider.vue' /* webpackChunkName: "components/slider" */).then(c => wrapFunctional(c.default || c)),
  SocialShareButtons: () => import('../../src/components/SocialShareButtons.vue' /* webpackChunkName: "components/social-share-buttons" */).then(c => wrapFunctional(c.default || c)),
  TextBanner: () => import('../../src/components/TextBanner.vue' /* webpackChunkName: "components/text-banner" */).then(c => wrapFunctional(c.default || c)),
  TierPrice: () => import('../../src/components/TierPrice.vue' /* webpackChunkName: "components/tier-price" */).then(c => wrapFunctional(c.default || c))
}

for (const name in components) {
  Vue.component(name, components[name])
  Vue.component('Lazy' + name, components[name])
}
